<template>
  <div class="user-tab-overview">
    <!-- user project list -->
    <div class="justify-end d-flex mb-3 mr-3">
      <!-- <v-chip color="success" @click="$emit('openSettingsAside')">
        <v-icon size="20" class="mr-1">{{ icons.mdiPlus }}</v-icon> Add
      </v-chip> -->
    </div>
    <v-card class="mb-7">
      <v-data-table :headers="tableColumnHeaders" :items="userId" hide-default-footer disable-pagination>
        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item link @click="$emit('openEditSettingsAside',item)">
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>

                <!-- <v-list-item link @click="deleteDistributor(item)">
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// import { getCheckouts } from '@/api/user'
import { mdiDotsVertical } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import { mdiPencilOutline, mdiDeleteOutline, mdiPlus } from '@mdi/js'

export default {
  components: {},
  props: {
    userId: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    props.userId
    // const loadCheckouts = async () => {
    //   try {
    //     const {
    //       data: { status, data },
    //     } = await getCheckouts(props.userId)

    //     if (status === 'success') {
    //       checkouts.value = data.records
    //     }
    //   } catch (error) {
    //     console.error(error.message)
    //   }
    // }

    onMounted(async () => {
      // if (props.userId) {
      //   await loadCheckouts()
      // }
    })

    const tableColumnHeaders = [
      {
        text: 'Category',
        value: 'category',
        sortable: false,
      },
      {
        text: 'Key',
        value: 'key',
        sortable: false,
      },
      {
        text: 'Value',
        value: 'value',
        sortable: false,
      },
      {
        text: 'Value Type',
        value: 'value_type',
        sortable: false,
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
      },
    ]

    return {
      tableColumnHeaders,
      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
